import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteReceiptMessage,
  updateReceiptMessage,
} from "../../redux/action/branchActions";
import { toggleKOT, togglePrinting } from "../../redux/action/utilActions";
import ActionLockSettings from "./ActionLockSettings";
import PrintSetting from "./PrintSetting";
import SyncOrderSettings from "./SyncOrderSettings";

const BranchAdminSettings = () => {
  const dispatch = useDispatch();
  const [receiptMessage, setReceiptMessage] = React.useState("");
  const [loading, setLoading] = React.useState();

  const {
    role,
    restaurantId,
    branchId,
    receiptMessage: customMessage,
    token,
  } = useSelector((state) => state.user);

  const { isOnline, isAppOffline } = useSelector(
    (state) => state.networkStatus
  );
  const isElectron = window?.api?.isElectron;

  const handleChange = (e) => {
    let input = e.target.value;
    const lines = input.split("\n");

    // Process each line individually
    const processedLines = lines.map((line) => {
      // Split the line into chunks of 30 characters
      const chunks = line.match(/.{1,30}/g) || [];
      return chunks.join("\n"); // Join chunks with newline
    });

    const processedText = processedLines.join("\n");

    // Update the state with the processed text
    setReceiptMessage(processedText);
  };

  const clearLoading = () => setLoading();
  const updateMessage = (e) => {
    setLoading("update");
    dispatch(
      updateReceiptMessage({
        message: receiptMessage,
        resId: restaurantId,
        branchId: branchId,
        cb: () => clearLoading(),
        errorCb: () => clearLoading(),
      })
    );
  };

  const deleteMessage = (e) => {
    dispatch(
      deleteReceiptMessage({
        message: receiptMessage,
        resId: restaurantId,
        branchId: branchId,
        cb: () => clearLoading(),
        errorCb: () => clearLoading(),
      })
    );
  };

  React.useEffect(() => {
    if (customMessage || customMessage !== null) {
      setReceiptMessage(customMessage);
    } else if (customMessage === null) {
      setReceiptMessage("");
    }
  }, [customMessage]);

  const handleOfflineMode = async () => {
    // window.api.sendData("toMain", "some data");
    // window.api.fecthOfflineData({ token: token }).then((res) => {
    //   console.log("fetchResponse", res);
    //   alert("hehe");
    // });
    const response = await window.api.fecthOfflineData({ token: token });
    console.log("response", response);

    // window.api.notify("hehe", () => {
    //   console.log("i am callback");
    // });
    // alert("hehe");
  };

  return (
    <div class="page-content-tab">
      <PrintSetting />
      <ActionLockSettings />
      {!isAppOffline && isOnline && isElectron && <SyncOrderSettings />}
      <Card>
        <Card.Header class="card-header bg-primary">
          <h5 class="text-white">Receipt message</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={3}>
              <textarea
                type="text"
                placeholder="Enter message to add below receipt"
                class="form-control"
                rows={"3"}
                value={receiptMessage}
                onChange={(e) => {
                  // const checked = e.target.checked;
                  // dispatch(togglePrinting(checked));
                  handleChange(e);
                }}
                // style={{ width: "230px" }}
              />
            </Col>
            <Col md={2}>
              <Button variant="primary" block onClick={() => updateMessage()}>
                {loading === "update" && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Update
              </Button>
            </Col>
            <Col md={2}>
              <Button
                variant="primary"
                block
                style={{ backgroundColor: "rgb(240, 88, 60)", border: "none" }}
                onClick={() => deleteMessage()}
              >
                {loading === "delete" && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Delete
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* <Card>
        <Card.Header class="card-header bg-primary">
          <h5 class="text-white">Offline Mode</h5>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col md={2}>
              <Button
                style={{ backgroundColor: "rgb(240, 88, 60)", border: "none" }}
                block
                onClick={() => handleOfflineMode()}
              >
                Go to offline mode
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card> */}
    </div>
  );
};

export default BranchAdminSettings;
