import React, { useEffect, useState } from "react";
import { Table, Container, Form, Button } from "react-bootstrap";
import {
  getAllDailyRawMaterials,
  updateDailyRawMaterial,
} from "../../redux/action/rawMaterialActions";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const general = {
  // cursor: "pointer",
  // overflow: "hidden",
  // textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // display: "inline-block",
  // padding: "0.25em 0.4em",
  // verticalAlign: "baseline",
  borderRadius: "10px",
  padding: "4px",
  textAlign: "center",
  height: "20px",
  lineHeight: "20px",
  width: "100px",
};
const success = {
  ...{ ...general },
  backgroundColor: "rgba(31, 140, 45, 0.15)",
  color: "#1f8c2d",
  boxShadow: "0px 0px 13px 0px rgb(31 140 45 / 5%)",
};

const danger = {
  ...{ ...general },
  backgroundColor: "rgba(168, 35, 35, 0.15)",
  color: "#a82323",
  boxShadow: "0px 0px 13px 0px rgb(168 35 35 / 5%)",
};

const InventoryTable = ({ tableData, onSubmit }) => {
  const { branchId } = useSelector((state) => state.user);

  // const transformedData = tableData.map((item) => ({
  //   itemName: item._id.itemName,
  //   date: item._id.createdAt,
  //   branchId: branchId,
  //   soldOutQuantity: item.soldOutQuantity?.toString(),
  // }));

  // const [updatedData, setUpdatedData] = useState(
  //   transformedData.length > 0 ? transformedData : []
  // );

  const [data, setData] = useState([]);
  // useEffect(() => {
  //   setUpdatedData(transformedData);
  // }, [tableData]);

  const dispatch = useDispatch();

  if (!tableData || tableData.length === 0) {
    return <div>No data available</div>;
  }

  const today = new Date();

  // Extract unique dates from the table data
  const dates = Array.from(
    new Set(
      tableData.map((item) => moment(item?._id?.createdAt).add(5, 'hours').add(30, 'minutes').format("DD-MM-YYYY"))
    )
  ).sort();

  // Group data by itemName
  const materials = Array.from(
    new Set(tableData.map((item) => item?._id?.itemName))
  ).sort((a, b) => a.localeCompare(b));

  const cellStyle = {
    padding: "8px",
    textAlign: "center",
    height: "20px",
    lineHeight: "20px",
    width: "100px",
    textWrap: "nowrap",
  };

  const inputStyle = {
    width: "100%",
    height: "20px",
    boxSizing: "border-box",
  };

  console.log(tableData);

  const handleChange = (itemName, branchId, value) => {
    setData((prevData) => {
      const existingItemIndex = prevData.findIndex(
        (item) => item.itemName === itemName
      );

      if (existingItemIndex !== -1) {
        const updatedData = [...prevData];
        updatedData[existingItemIndex] = {
          ...updatedData[existingItemIndex],
          soldOutQuantity: value,
        };
        return updatedData;
      } else {
        return [...prevData, { itemName, branchId, soldOutQuantity: value }];
      }
    });
  };
  // const handleChange = (itemName, date, branchId, field, value) => {
  //   setUpdatedData((prev) => {
  //     const existingEntryIndex = prev.findIndex(
  //       (entry) => entry.itemName === itemName && entry.date === date
  //     );

  //     if (existingEntryIndex !== -1) {
  //       const updatedEntry = {
  //         ...prev[existingEntryIndex],
  //         [field]: value,
  //       };

  //       return [updatedEntry];
  //     } else {
  //       return [
  //         {
  //           itemName: itemName,
  //           date: date,
  //           branchId: branchId,
  //           [field]: value,
  //         },
  //       ];
  //     }
  //   });
  // };

  const handleSubmit = () => {
    console.log("data", data);
    dispatch(updateDailyRawMaterial(data)).then(() => {
      dispatch(getAllDailyRawMaterials(branchId));
      setData([]);
    });

    // Filter out only the entries that have changed
    // const changedData = updatedData.filter((entry) => {
    //   const originalEntry = transformedData.find(
    //     (item) => item.itemName === entry.itemName && item.date === entry.date
    //   );
    //   return (
    //     originalEntry && originalEntry.soldOutQuantity !== entry.soldOutQuantity
    //   );
    // });

    // Only dispatch if there is changed data
    // if (changedData.length > 0) {
    //   dispatch(updateDailyRawMaterial(changedData));
    //   dispatch(getAllDailyRawMaterials(branchId));
    // } else {
    //   console.log("No changes detected, nothing to update.");
    // }

    // setUpdatedData([]);
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card inventory-table">
          <div className="card-body">
            <Container fluid>
              <div style={{ display: "table", width: "100%" }}>
                {/* Left Fixed Section */}
                <div
                  style={{
                    display: "table-cell",
                    width: "200px",
                    verticalAlign: "top",
                  }}
                >
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th style={{ ...cellStyle, textWrap: "nowrap" }}>
                          Material Name
                        </th>
                        <th style={cellStyle}>Issue Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materials.map((material, index) => (
                        <tr key={index}>
                          <td style={{ ...cellStyle, textWrap: "nowrap" }}>
                            {material}
                          </td>
                          <td
                            style={{
                              ...cellStyle,
                              color: "red",
                              minWidth: "70px",
                            }}
                          >
                            <Form.Control
                              type="text"
                              style={{ ...inputStyle, color: "red" }}
                              value={
                                data.find((item) => item.itemName === material)
                                  ?.soldOutQuantity || ""
                              }
                              onChange={(e) =>
                                handleChange(material, branchId, e.target.value)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {/* Middle Scrollable Section */}
                <div
                  style={{
                    display: "table-cell",
                    overflowX: "auto",
                    verticalAlign: "top",
                    maxWidth: "300px",
                  }}
                >
                  <Table bordered hover style={{ minWidth: "max-content" }}>
                    <thead>
                      <tr>
                        {dates.map((date, index) => (
                          <th key={index} colSpan="2" style={cellStyle}>
                            {date}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {materials.map((material, index) => (
                        <tr key={index}>
                          {dates.map((date, i) => {
                            const item = tableData.find(
                              (entry) =>
                                entry._id.itemName === material &&
                              moment(entry?._id?.createdAt).add(5, 'hours').add(30, 'minutes').format("DD-MM-YYYY") === date
                            );
                            const purchaseQty = item
                              ? item.purchaseQuantity
                              : 0;
                            const soldOutQty = item ? item.soldOutQuantity : 0;

                            const measureUnit = item?.measureUnit ?? "";

                            return (
                              <React.Fragment key={i}>
                                <td
                                  style={{
                                    ...cellStyle,
                                    color: "green",
                                    maxWidth: "70px",
                                  }}
                                >
                                  {purchaseQty + " " + measureUnit}
                                </td>
                                <td
                                  style={{
                                    ...cellStyle,
                                    color: "red",
                                    maxWidth: "70px",
                                  }}
                                >
                                  {soldOutQty + " " + measureUnit}
                                  {/* {item?._id?.itemName === material &&
                                  date === today ? (
                                    <Form.Control
                                      type="text"
                                      value={
                                        updatedData.find(
                                          (entry) =>
                                            entry.itemName === material &&
                                            entry.date === date
                                        )?.soldOutQuantity ??
                                        item?.soldOutQuantity ??
                                        ""
                                      }
                                      style={{...inputStyle, color: "red"}}
                                      onChange={(e) =>
                                        handleChange(
                                          material,
                                          date,
                                          branchId,
                                          "soldOutQuantity",
                                          e.target.value
                                        )
                                      }
                                    />
                                  ) : (
                                    
                                  )} */}
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {/* Right Fixed Section */}
                <div
                  style={{
                    display: "table-cell",
                    width: "300px",
                    verticalAlign: "top",
                  }}
                >
                  <Table bordered hover>
                    <thead>
                      <tr>
                        <th style={cellStyle}>Available Qty</th>
                        <th style={cellStyle}>Lower Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {materials.map((material, index) => {
                        const item = tableData.find(
                          (entry) =>
                            entry?._id?.itemName === material &&
                          moment(entry?._id?.createdAt).add(5, 'hours').add(30, 'minutes').format("DD-MM-YYYY") === moment(today).format("DD-MM-YYYY")
                        );

                        const measureUnit = item?.measureUnit ?? "";

                        return (
                          <tr key={index}>
                            <td style={{ textAlign: "center" }}>
                              <span
                                style={
                                  item?.currentStock <= item?.lowerLimitQuantity
                                    ? { ...danger }
                                    : { ...success }
                                }
                              >
                                {(item?.currentStock || 0) + " " + measureUnit}
                              </span>
                            </td>
                            <td style={cellStyle}>
                              {(item?.lowerLimitQuantity || 0) +
                                " " +
                                measureUnit}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Container>
            <Button variant="primary" onClick={handleSubmit}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryTable;
