import React from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PermissionsGate from "../../components/PermissionGate";
import { SCOPES } from "../../contants";
import { togglePrintSetting } from "../../redux/action/userActions";
const PrintSetting = () => {
  const dispatch = useDispatch();

  const handleChangeCheckBox = (name, value) => {

    if(name === "enablePrinting"){
      dispatch(
        togglePrintSetting({
          enablePrinting: value,
          enableLogo: value,
          enableAddress: value,
          enableGSTNumber: value,
          enableCustomer: value,
          enableRemark: value,
          enableBranchName: value,
          enableFssaiNumber: value,
        })
      );  
    }
    else{
      dispatch(
        togglePrintSetting({
          [name]: value,
        })
      );
    }
  };

  const buttonStyle = (name) => {
    switch(name){
      case "enableLogo":
      case "enableAddress":
      case "enableBranchName":
      case "enableGSTNumber":
      case "enableCustomer":
      case "enableRemark":
      case "enableFssaiNumber":
        return {marginLeft: "10px"}

      default:
        return {}

    }
  }

  const {
    enablePrinting,
    enableBranchName,
    enableKOT,
    enableKDS,
    enableLogo,
    enableAddress,
    enableGSTNumber,
    enableCustomer,
    enableRemark,
    enableFssaiNumber,
  } = useSelector((state) => state.util);

  const renderCheckboxInput = ({ name, label, onChange, value }) => {
    return (
      <div class="custom-control custom-switch switch-primary" style={buttonStyle(name)}>
        <input
          type="checkbox"
          class="custom-control-input"
          id={`customSwitchPrimary${name}`}
          checked={value}
          onChange={(e) => {
            const checked = e.target.checked;
            onChange(name, checked);
          }}
        />
        <label class="custom-control-label" for={`customSwitchPrimary${name}`}>
          {label}
        </label>
      </div>
    );
  };

  return (
    <div>
      <Card>
        <Card.Header class="card-header bg-primary">
          <h5 class="text-white"> Setting</h5>
        </Card.Header>
        <Card.Body>
          {renderCheckboxInput({
            name: "enableKOT",
            value: enableKOT,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable KOT",
          })}
          <PermissionsGate scopes={[SCOPES.KITCHEN_DISPLAY_SYSTEM]}>
            {renderCheckboxInput({
              name: "enableKDS",
              value: enableKDS,
              onChange: (name, value) => handleChangeCheckBox(name, value),
              label: "Enable Kitchen Display System",
            })}
          </PermissionsGate>

          {renderCheckboxInput({
            name: "enablePrinting",
            value: enablePrinting,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: " Enable Bill Printing",
          })}

          {renderCheckboxInput({
            name: "enableLogo",
            value: enableLogo,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable Logo Printing",
          })}
          {renderCheckboxInput({
            name: "enableBranchName",
            value: enableBranchName,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable Branch Name Printing",
          })}

          {renderCheckboxInput({
            name: "enableAddress",
            value: enableAddress,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable Branch Address Printing",
          })}
          {renderCheckboxInput({
            name: "enableGSTNumber",
            value: enableGSTNumber,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable GST Number Printing",
          })}
          {renderCheckboxInput({
            name: "enableCustomer",
            value: enableCustomer,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable Cutomer Info Printing",
          })}
          {renderCheckboxInput({
            name: "enableRemark",
            value: enableRemark,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable Remark Info Printing",
          })}
          {renderCheckboxInput({
            name: "enableFssaiNumber",
            value: enableFssaiNumber,
            onChange: (name, value) => handleChangeCheckBox(name, value),
            label: "Enable FSSAI Number Printing",
          })}
        </Card.Body>
      </Card>
    </div>
  );
};

export default PrintSetting;
